<template>
  <v-dialog
    :max-width="$dialog.content"
    :value="show"
    scrollable 
    persistent
  >
    <close-btn @click="close()" depressed overflow />

    <v-card>
      <v-card-title class="mb-2 justify-center">
        <h5 class="font-weight-bold text-h5">
          {{ status.id ? 'Edit Category' : 'Create New Category' }}
        </h5>
      </v-card-title>
      <v-card-text>
        <v-alert v-if="status.error" transition="fade" type="error" border="left" text>
          {{ status.error }}
        </v-alert>

        <v-form ref="typeForm" @submit.prevent="validateForm()">
          <v-progress-linear v-if="status.progress" :value="status.progress" class="mb-2" rounded></v-progress-linear>
          
          <v-file-input
            accept="image/png, image/jpeg, image/svg+xml"
            prepend-inner-icon="mdi-image"
            v-model="status.image"
            v-if="!data.image"
            prepend-icon=""
            label="Image"
            outlined
          ></v-file-input>

          <type-image
            @delete="deleteImage()"
            v-if="data.image"
            :type="data"
            editable
          />

          <v-text-field
            v-model="data.name"
            :rules="[rules.required]"
            label="Name"
            outlined
          ></v-text-field>
          
          <v-textarea
            v-model="data.description"
            label="Description"
            outlined
          ></v-textarea>

          <v-btn 
            @click="validateForm()"
            :loading="status.creating"
            class="gradient"
            color="accent"
            block
          >Submit</v-btn>
        </v-form>
      </v-card-text>

      <!-- CONFIRM DELETE -->

      <confirm-delete
        :show="deleteImg"
        :deleting="status.deletingImage"
        message="Are you sure you want to delete this image?"
        @confirmed="deleteConfirmed()"
        @cancel="closeDeleteImage()"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

import TypeImage from './TypeImage'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    show: Boolean
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      deleteImg: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    TypeImage
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.types.data,
      status: state => state.types.status,
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'status.uploading': function (val) {
      if (!val) {
        this.close()
      }
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('types', [
      'createType',
      'updateType',
      'deleteTypeImage'
    ]),

    close() {
      this.$emit('close')

      setTimeout(() => {
        this.$store.commit('types/resetData')
        this.$refs.typeForm.resetValidation()
      }, 2000)
    },

    validateForm() {
      if (this.$refs.typeForm.validate()) {
        let task

        if (!this.data.id) {
          task = Promise.all([
            this.createType()
          ])
        }
        else {
          task = Promise.all([
            this.updateType()
          ])
        }

        task.then(response => {
          if (!response[0]) {
            if (!this.status.uploading) this.close()
          }
        })
      }
    },

    deleteImage() {
      this.deleteImg = true
    },

    closeDeleteImage() {
      this.deleteImg = false
    },

    deleteConfirmed() {
      Promise.all([
        this.deleteTypeImage(this.data)
      ])
      .then(() => {
        this.closeDeleteImage()
      })
    }
  }
}
</script>
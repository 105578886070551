<template>
  <div v-if="user">
    <template v-if="canRequest && !checkingAccess" >
      <admin-title title="Requests" class="mb-n5">
        <template #actions>
          <v-btn
            v-if="(user.role == 'admin' || user.manageRequests) || (user.role == 'client' && $store.getters['request/activeRequests'].length < $store.getters['generalSettings/allowedActiveRequests'])"
            :to="{ name: 'SelectRequestType' }"
            color="white primary--text"
            small
          >Create A Request</v-btn>
        </template>
      </admin-title>

      <v-alert 
        :value="user.role === 'client' && $store.getters['request/activeRequests'].length >= $store.getters['generalSettings/allowedActiveRequests']"
        type="warning"
        border="left"
        text
      >
        You have reached the maximum number of active requests for your package. Check your active requests, mark pending requests as completed or pause some requests so you can submit more requests
      </v-alert>

      <v-tabs
        v-if="user"
        v-model="tab"
        color="primary"
        slider-color="primary"
        class="request-tabs"
      >
        <v-tab :to="{ name: 'OpenRequests' }" exact>
          <span class="slider-title">In Progress</span>
        </v-tab>
        <v-tab v-if="user.role !== 'client'" :to="{ name: 'PendingReviewRequests' }" exact>
          <span class="slider-title">Pending Client Review</span>
        </v-tab>
        <v-tab :to="{ name: 'CompletedRequests' }">
          <span class="slider-title">Completed</span>
        </v-tab>
        <v-tab :to="{ name: 'RequestTypes' }" v-if="user && (user.role == 'admin' || (user.manageRequests && user.manageRequestForm))">
          <span class="slider-title">Forms</span>
        </v-tab>
        <v-tab :to="{ name: 'RequestPackages' }" v-if="user && (user.role == 'admin' || (user.manageRequests && user.manageRequestPackages))">
          <span class="slider-title">Packages</span>
        </v-tab>
        <v-tab :to="{ name: 'RequestCannedResponses' }" v-if="user && (user.role == 'admin' || (user.manageRequests && user.manageRequestCannedResponses))">
          <span class="slider-title">Canned Responses</span>
        </v-tab>
      </v-tabs>

      <router-view></router-view>

      <create :show="createDialog" @close="closeDialog" />
    </template>

    <v-alert v-else-if="user" type="info" border="left">
      You must be an existing client or had previous orders to access request feature.
    </v-alert>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import create from './components/CreateDialog'

export default {
  name: 'Requests',

  metaInfo: {
    title: 'Requests'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      tab: null,
      canRequest: false,
      createDialog: false,
      checkingAccess: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'user': {
      handler(user) {
        if (user) this.checkUserAccess()
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    create,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    showDialog() {
      this.createDialog = true
    },

    closeDialog() {
      this.createDialog = false
    },

    checkUserAccess() {
      this.checkingAccess = true

      if (['client', 'admin'].includes(this.user.role) || this.user.organization) {
        this.canRequest = true
        this.checkingAccess = false
      }
    }
  },
}
</script>